$surface: #000000;

$elevation-minus10-indent: #727272;
$elevation-minus70-fieldSelected: #404040;

$onSurface-highEmph: #FFFFFFFF;
$onSurface-mediumEmph-light: #FFFFFF99;

$error-plus1-onSurface: #FC3034;

$grid-side: 94px;
$grid-column-gutter: 24px;