body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @font-face {
    font-family: MatterSQ;
    src: 
      url("https://d33wubrfki0l68.cloudfront.net/64fd4ec77eb21c37862b7f1febee3f3164a6f6ef/1716c/_nuxt/fonts/matterraumgleiter-regular.44f8bf9.woff2")
      format("woff2"),url("https://d33wubrfki0l68.cloudfront.net/ddd8b64144c7aa24888acdb98551f02e76f98b36/7b39f/_nuxt/fonts/matterraumgleiter-regular.ada4df3.woff")
      format("woff");
      font-weight:400;
      font-style:normal;
      font-display:swap;
      unicode-range:u+000-5ff
  }
  @font-face{
    font-family:MatterSQ;
    src:
      url("https://d33wubrfki0l68.cloudfront.net/8204e0754a2b6ebf1ef33475340c615dfa9f1e2a/c5008/_nuxt/fonts/matterraumgleiter-semibold.a9cc51b.woff2")
      format("woff2"),
      url("https://d33wubrfki0l68.cloudfront.net/d83f15768c4592984c3820319ec5d862bcdd2513/f2c0d/_nuxt/fonts/matterraumgleiter-semibold.4d0fb6b.woff")
      format("woff");
      font-weight:700;
      font-style:normal;
      font-display:swap;
      unicode-range:u+000-5ff
  }
  @font-face{
    font-family:MatterSQ;
    src:
      url("https://d33wubrfki0l68.cloudfront.net/f9ecb08e763e5043338e65ce81c3fb9a2469118a/a9395/_nuxt/fonts/matterraumgleiter-heavy.1288088.woff2")
      format("woff2"),
      url("https://d33wubrfki0l68.cloudfront.net/a5f4fd6d36ce5ac18ff8c065a2b166cc112bdfeb/883c1/_nuxt/fonts/matterraumgleiter-heavy.e9022da.woff") 

      format("woff");
      font-weight:900;
      font-style:normal;
      font-display:swap;
      unicode-range:u+000-5ff
  }

  font-family:MatterSQ,-apple-system,sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.NoUser,
.NotSetUp {
  width: 100%;
  text-align: center;
}