@import './variables.scss';

.OvertimeChart {
  // margin-left: $grid-side;
  // margin-right: $grid-side;
  width: 100%;
  height: 35vh;
  margin-bottom: 2rem;
  // margin-bottom: 5vh;

  outline: none;
  touch-action: none;
  user-select: none;

  text {
    font-size: 16px;
  }

  .InteractiveArea {
    cursor: pointer;
    fill: none;
    // fill: $error-plus1-onSurface;
    opacity: 0.2;
  }

  .ChartLine {
    fill: none;
    stroke: url(#line-gradient);
    stroke-width: 2;
    stroke-linejoin: round;
    stroke-linecap: round;
  }
  // .ChartLine.Prediction {
    // opacity: 0.4;
  // }

  .ChartArea {
    fill: url(#area-gradient);
    stroke: none;
    stroke-width: 0;
    stroke-linejoin: round;
    stroke-linecap: round;

    opacity: 0.7;
  }
  .ChartArea.Defined {
    opacity: 0.3;
  }

  .ChartAxis {
    .tick {
      line {
        stroke: #ffffff66;
        stroke-width: 1;
      }
      line.ZeroAxis {
        stroke: #ffffffff;
      }
      
      text {
        color: #ffffff66;
        text-anchor: end;
      }
      text.ZeroAxis {
        color: #ffffffff;
      }
    }
  }

  .HoverCircle {
    fill: #ffffff;
    r: 10px;
  }
}