@import './variables.scss';

.TimeList {
  padding-left: #{$grid-side - $grid-column-gutter};
  padding-right: #{$grid-side - $grid-column-gutter};
  margin-bottom: 10vh;
  // padding-top: 152px;
  // top: 152px;
  // bottom: 0px;
  
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  > div {
    display: flex;
    flex-direction: column;
    
    h3 {
      margin-top: 0px;
      margin-bottom: 8px;
    }
  }
  .Date,
  .Worked,
  .Required,
  .Change,
  .Overtime {
    flex-shrink: 0;    
    
    margin-left: $grid-column-gutter;
    margin-right: $grid-column-gutter;
    
    outline: none;
    touch-action: none;
    user-select: none;
  }
  .Date,
  .Worked,
  .Required {
    // margin-right: 56px;
    text-align: start;
  }
  .Change,
  .Overtime {
    text-align: end;
  }

  .Label {
      margin-bottom: 40px;
  }

  .Date {
    opacity: 0.6;
  }
  .Selectable {
    opacity: 0.6;
    cursor: pointer;
  }
  .Selectable:hover {
    opacity: 0.8;
  }
  .Selectable.IsSelected {
    opacity: 1.0;
    cursor: pointer;
  }
  .NotSelected {
    opacity: 0.6;
  }
  
  .ListEntry {
    position: relative;
    margin-bottom: 20px;
    
    >div {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100vw;
      height: calc(100% + 20px);
      // background-color: $error-plus1-onSurface;
    }
  }
  h3.Negative,
  .Label.Negative,
  .ListEntry.Negative {
    color: $error-plus1-onSurface;
  }
}

.TotalsList {
  display: flex;
  flex-direction: column;

  .Progress {
    margin-bottom: 20px;
    margin-left: #{$grid-side - $grid-column-gutter};
    margin-right: #{$grid-side - $grid-column-gutter};

    height: 2px;
    background-color: $onSurface-mediumEmph-light;

    .ProgressBar {
      height: 100%;
      width: 50%;
      background-color: $onSurface-highEmph;
    }
  }
}