@import './variables.scss';

h1 {
  font-size: 64px;
  font-weight: 900;
}
h3 {
  font-size: 48px;
  font-weight: 700;
}
span {
  font-size: 16px;
  font-weight: 400;
}
a {
  color: $onSurface-highEmph;
  text-decoration: none;
  font-weight: 700;
}
a:hover {
  text-decoration: underline;
}

body {
  background-color: $surface;
  color: $onSurface-highEmph;
}

// .App {
//   text-align: center;
// }

// .App-header {
//   min-height: 100vh;
  
//   font-size: calc(10px + 2vmin);
//   color: white;
  
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   justify-content: center;
// }
