@import './variables.scss';

.SignIn {
  height: 28px;
  width: 338px;
  align-self: center;
  margin-left: auto;
  margin-right: auto;

  font-size: 16px;
  background-color: $elevation-minus70-fieldSelected;
  border-radius: 999px;

  display: flex;

  input {
    outline: none;
  }
  input[type="email"] {
    flex-grow: 3;
    padding-left: 10px;

    color: $onSurface-highEmph;
    background: none;
    border: none;
  }
  input[type="email"]::placeholder {
    color: $onSurface-highEmph;
    // color: $onSurface-mediumEmph-light;
    border: none;
  }
  input[type="submit"] {
    flex-grow: 1;

    color: $onSurface-highEmph;
    background-color: $elevation-minus10-indent;
    border-radius: 999px;
    border: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: none;
    border-radius: 999px;
    -webkit-text-fill-color: $onSurface-highEmph;
    box-shadow: 0 0 0px 1000px $elevation-minus70-fieldSelected inset;
    transition: background-color 5000s ease-in-out 0s
  }
}