@import './variables.scss';

.BaseLayout {
  // position: absolute;
  // top: 0px;
  // left: 0px;

  .Title {
    margin-top: 45px;
    margin-bottom: 15vh;
    // margin-bottom: 10rem;
    margin-left: $grid-side;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h1 {
      margin-top: 0px;
      margin-bottom: 10px;
    }
  }
}