@import './variables.scss';

.AllUsersView {
  padding-left: 40px;
  padding-right: 40px;

  a {
    width: 100%;
    text-align: center;
  }

  table {
    width: 100%;
    margin-top: 40px;
  }
  .Negative {
    color: $error-plus1-onSurface;
  }
}